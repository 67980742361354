import { Grid, Typography } from "@material-ui/core"
import React, { ReactElement } from "react"
import InstagramEmbed from "react-instagram-embed"

import BlogPost from "../../../../components/BlogPost"
import { Link } from "../../../../components/Link"
import { SearchResults } from "../../../../components/SearchResults"

interface Props {
  data: any
  location: Location
  pageContext: any
}

export default function Post(props: Props): ReactElement {
  const { location, pageContext } = props
  return (
    <BlogPost
      location={location}
      pageContext={pageContext}
      title="Kroko-Optik"
      category="Wintertrends 2019/2020"
    >
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12} md={6}>
          <Typography paragraph={true}>
            Ob Handtaschen oder Schuhe: Der Kroko-Trend ist diesen Winter zurück.{" "}
            <Link href="/henkeltaschen/" underline="always">
              Henkeltaschen
            </Link>{" "}
            von POON Switzerland oder Henkeltaschen von Silvio Tossi. Die Designer bringen uns mit
            ihrem Kroko-Effekt zum Staunen. In der Stadt, in einem Cafe oder im Restaurant mit
            Freunden: Kroko ist überall Willkommen. Die Schultertaschen aus Kroko ziehen jeden Blick
            auf sich, so dass man einen glamourösen und eleganten Eindruck hinterlässt. Eine braune{" "}
            <Link href="/shopper/" underline="always">
              Shopper
            </Link>{" "}
            von HALLHUBER oder eine{" "}
            <Link href="/guerteltaschen/" underline="always">
              Gürteltasche
            </Link>{" "}
            von INYATI sind absolute Eye-Catcher.
          </Typography>
          <InstagramEmbed
            url="https://www.instagram.com/p/B24zBDeA_9S/"
            hideCaption={true}
            injectScript={true}
          />
        </Grid>
        <Grid item={true} xs={12} md={6}>
          <SearchResults
            breakpoints={{
              lg: 6,
              md: 6,
              xs: 6,
            }}
            results={[
              {
                href: "https://www.awin1.com/pclick.php?p=25093209965&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/33714053?w=2500&h=2500",
                    title: "",
                  },
                ],
                name: "INYATI Gürteltasche",
                price: 49.95,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24609034413&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src:
                      "https://i.otto.de/i/otto/9c925588-6f1d-5603-b835-22fa589d5476?w=2500&h=2212",
                    title: "",
                  },
                ],
                name: "Silvio Tossi Henkeltasche",
                price: 79.9,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24609083695&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src:
                      "https://i.otto.de/i/otto/c97ed3dc-005e-5237-a2c5-a8cba7b2a316?w=2500&h=2212",
                    title: "",
                  },
                ],
                name: "POON Switzerland Henkeltasche",
                price: 79.9,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24609085053&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src:
                      "https://i.otto.de/i/otto/fea4e97d-702a-5b13-b918-32a7e22ba772?w=2500&h=2212",
                    title: "",
                  },
                ],
                name: "POON Switzerland Henkeltasche",
                price: 79.9,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24609042625&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/33863765?w=1678&h=2500",
                    title: "",
                  },
                ],
                name: "COLLEZIONE ALESSANDRO Schultertasche",
                price: 119.9,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25500244879&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src:
                      "https://i.otto.de/i/otto/a2dd806e-3770-5db3-9dd1-d07467abc725?w=1524&h=2200",
                    title: "",
                  },
                ],
                name: "HALLHUBER Shopper",
                price: 59.99,
              },
            ]}
          />
        </Grid>
      </Grid>

      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12} md={12}>
          <SearchResults
            results={[
              {
                href: "https://www.awin1.com/pclick.php?p=24943385171&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src:
                      "https://i.otto.de/i/otto/ca8e84a0-17c2-50f3-b479-26e1d26e38fb?w=2500&h=2212",
                    title: "",
                  },
                ],
                name: "Silvio Tossi Henkeltasche",
                price: 79.9,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24672828833&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src:
                      "https://i.otto.de/i/otto/67cd4b7c-64a0-53c0-8af5-0307d78c09a3?w=2500&h=2212",
                    title: "",
                  },
                ],
                name: "Silvio Tossi Henkeltasche",
                price: 79.9,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24936126435&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/35297785?w=1750&h=2500",
                    title: "",
                  },
                ],
                name: "JETTE Mini Bag",
                price: 44.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24608681129&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/34509654?w=2000&h=1999",
                    title: "",
                  },
                ],
                name: "TOM TAILOR Shopper",
                price: 45.99,
              },
            ]}
          />
        </Grid>
      </Grid>
    </BlogPost>
  )
}
